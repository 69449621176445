<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>ユーザー作成</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="home">
          <div class="user">
            <form v-on:submit.prevent="create">
              <div class="mb-3">
                <label class="form-label">email</label>
                <input v-model="email" type="email" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">パスワード</label>
                <input v-model="password" type="password" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">ID</label>
                <input v-model="user_identifier" type="text" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">氏名</label>
                <input v-model="name" type="text" class="form-control" required />
              </div>
              <div class="mb-3">
                <label>生年月日</label>
                <input v-model="birthday" type="date" pattern="yyyy-mm-dd" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">登録日</label>
                <input v-model="joinday" type="date" pattern="yyyy-mm-dd" class="form-control" required />
              </div>
              <!-- <div class="mb-3">
              <label class="form-label">入社時経験月数</label>
              <input v-model="months_at_join" type="number" class="form-control" required/>
            </div>
            <div class="mb-3">
              <label class="form-label">メモ</label>
              <input v-model="memo" type="text" class="form-control" required/>
            </div>
            <div class="mb-3">
              <label class="form-label">所属</label>
              <input v-model="belongs" type="text" class="form-control" required/>
            </div>
            <div class="mb-3">
              <label class="form-label">役割</label>
              <input v-model="role" type="text" class="form-control" required/>
            </div>
            <div class="mb-3">
              <label class="form-label">資格</label>
              <input v-model="qualification" type="text" class="form-control" required/>
            </div> -->
              <div class="mb-3">
                <label for="userTypeSelect">ユーザータイプ</label>
                <select id="userTypeSelect" v-model="user_type" class="form-control">
                  <option v-for="ut in user_types" :key="ut.id" :value="ut.id">{{ ut.name }}</option>
                </select>
              </div>
              <div class="buttons mb-3">
                <button class="btn btn-primary row-button">作成</button>
              </div>
              <div class="buttons mb-3">
                <button v-on:click="back" class="btn btn-primary row-button">戻る</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

const NORMAL_USER_TYPE = '1'
const SUPER_USER_TYPE = '2'
const NON_USER_TYPE = '3'

export default {
  data: function () {
    return {
      email: "",
      password: "",
      user_id: "",
      user: {},
      name: "",
      user_identifier: "",
      birthday: "",
      joinday: "",
      self_introduction: "",
      career_summary: "",
      fileInfo: '',
      fileSetted: false,
      user_type: NORMAL_USER_TYPE,
      user_types: [{ id: NORMAL_USER_TYPE, name: "一般ユーザー" }
        , { id: SUPER_USER_TYPE, name: "管理者" }
        , { id: NON_USER_TYPE, name: "お知らせ投稿用" }],
    }
  },
  async mounted() {
  },
  methods: {
    create: function () {

      var is_superuser = false;
      var is_nonuser = false;
      if (this.user_type == NON_USER_TYPE) {
        is_nonuser = true;
      } else if (this.user_type == SUPER_USER_TYPE) {
        is_superuser = true;
      }

      const user_data = {
        email: this.email,
        password: this.password,
        is_superuser: is_superuser,
        is_nonuser: is_nonuser,
      }

      let data = {
          user_id: this.user_id,
          name: this.name,
          user_identifier: this.user_identifier,
          birthday: this.birthday,
          joinday: this.joinday,
          self_introduction: "",
          career_summary: "",
          role: this.role,
          is_superuser: is_superuser,
          is_nonuser: is_nonuser,
        };
      if (data.user_id != "") {
        axios.post("/user_info_create_update", data)
        .then((res) => {
          // 成功時の処理
          router.push("/users");
        })
        .catch((error) => {
          alert(error.response.data.detail);
        });
        return;
      }
      axios.post("/user_create", user_data).then((res) => {
        this.user_id = String(res.data.id)
        data.user_id = this.user_id;
        axios.post("/user_info_create_update", data)
        .then((res) => {
          // 成功時の処理
          router.push("/users");
        })
        .catch((error) => {
          alert(error.response.data.detail);
        });
      });
    },
    back: function () {
      router.push("/menu")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
.home {
/*  width: 900px;*/
/*  margin: 0 auto;*/
}

label {
  display: block;
  text-align: left;
}

.buttons {
  text-align: left;
}

img {
  width: 300px;
  height: 400px;
}
</style>
