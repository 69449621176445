<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>ポイント情報出力</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="home">
          <div class="user">
            <form v-on:submit.prevent="create">
            <div class="mb-3">
              <label class="form-label">年</label>
              <input v-model="at_year" type="text" pattern="\d{4}" required class="form-control" placeholder="2022"/>
            </div>
            <div class="mb-3">
              <label class="form-label">月</label>
              <input v-model="at_month" type="text" pattern="\d{1,2}" required class="form-control" placeholder="04"/>
            </div>

            <div class="buttons mb-3">
              <button type="submit" class="btn btn-primary">出力</button>
            </div>
            <div class="buttons mb-3">
              <button v-on:click="back" class="btn btn-primary">戻る</button>
            </div>
            </form>
          </div>
          <div v-if="histories.length > 0">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">出力日時</th>
                  <th scope="col">出力年月</th>
                </tr>
              </thead>
              <tr v-for="r in histories" :key="r.id">
                <td>{{date_format(r.output_date)}}</td>
                <td>{{r.year}}-{{r.month}}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      at_year: "",
      at_month: "",
      histories: [],
    }
  },
  mounted() {
    this.get_history();
  },
  methods: {
    get_history: async function()  {
      await axios.get("/point_output_history", {}).then((response) => {
        this.histories = response.data
      });
    },
    date_format: function(date) {
      return date.replace("T", " ").replace(/-/g, "/").replace(/\..*/,"")
    },
    create: function () {
      const data = {
        at_year: this.at_year,
        at_month: this.at_month,
      }
      axios.post("/point_infos",  data).then((res) => {
        let csv = ""
        let csvdata = res.data
        console.log(csvdata)
        csvdata.split("\n").forEach((row) => {
          csv += row
          csv += "\n";
        });

        const blob = new Blob(['\ufeff' + csv], { type: 'text/csv;charset=utf-8;' });
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
          dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `point_info_${this.at_year}-${this.at_month}.csv`);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        this.get_history();
      });
    },
    back: function () {
      router.push("/menu")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
.home {
  /*  width: 900px;
    margin: 0 auto;*/
}

label {
  display: block;
  text-align: left;
}

.buttons {
  text-align: left;
}

img {
  width: 300px;
  height: 400px;
}
</style>
