<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>お知らせ追加</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="home">
          <div class="user">
            <form v-on:submit.prevent="create">
              <div class="mb-3">
                <label class="form-label">タイトル</label>
                <input v-model="info_title" type="text" class="form-control" required placeholder="お知らせタイトル" />
              </div>
              <div class="mb-3">
                <label class="form-label">本文</label>
                <textarea v-model="info_body" type="email" class="form-control" required placeholder="お知らせ本文" />
              </div>

              <div class="mb-3">
                <label class="form-label">お知らせ日時</label>
                <input v-model="issue_date" type="date" pattern="\d\d\d\d\-\d\d\-\d\d" class="form-control" required
                  placeholder="2022-10-01" />
              </div>

              <div class="mb-3">
                <label for="push">プッシュ配信</label>
                <select v-model="selectedOption" class="form-control">
                  <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <label class="form-label">プッシュメッセージ</label>
                <input v-model="push_body" type="text" class="form-control" required placeholder="プッシュメッセージ" />
              </div>
              <br>
              <div class="buttons mb-3">
                <button type="submit" class="btn btn-primary">作成</button>
              </div>
              <div class="buttons mb-3">
                <button v-on:click="back" class="btn btn-primary">戻る</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      info_title: "",
      info_body: "",
      issue_date: "",
      push_body: "",
      is_push: "0",
      selectedOption: '0', // 選択されたオプションの初期値
      options: [ // プルダウンの選択肢
        { label: 'プッシュ配信しない', value: '0' },
        { label: 'プッシュ配信する', value: '1' },
      ],
    }
  },
  async mounted() {
  },
  methods: {
    create: function () {
      const data = {
        info_title: this.info_title,
        info_body: this.info_body,
        issue_date: this.issue_date,
        push_body: this.push_body,
        is_push: this.selectedOption
      }
      axios.post("/info", data).then((res) => {
        router.push("/infos")
      }).catch((error) => {
        alert(error.response.data.detail);
      });
    },
    back: function () {
      router.push("/menu")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
.home {
  /*  width: 900px;
    margin: 0 auto;*/
}

label {
  display: block;
  text-align: left;
}

.buttons {
  text-align: left;
}

img {
  width: 300px;
  height: 400px;
}
</style>
