<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1 style="text-align: center">ユーザー編集</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <form id="form" v-on:submit.prevent="dummy">
          <div class="mb-3">
            <img :src="user.image" class="d-block" />
            <div>
              <input type="file" class="mt-4" v-on:change="fileSelected" />
              <button v-if="fileSetted == true" v-on:click="fileUpload" class="btn btn-primary mt-2">アップロード</button>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">email</label>
            <span class="text">{{email}}</span>
          </div>
          <div class="mb-3">
            <label class="form-label">ID</label>
            <input v-model="user_identifier" type="text" class="form-control" required />
          </div>
          <div class="pass">
            <button v-on:click="ui_open" class="btn btn-primary mb-2">パスワード更新UI</button>
            <div v-if="password_change_ui==true">
              <div class="mb-3">
                <label class="form-label">新しいパスワード</label>
                <input type="password" v-model="password" ref="password_textbox" class="form-control">
              </div>
              <div class="mb-3">
                <label class="form-label">新しいパスワード（確認）</label>
                <input type="password" v-model="password_confirm" class="form-control">
              </div>
              <div class="mb-3">
                <div>
                  <button v-on:click="update_password" class="btn btn-primary">パスワード更新</button>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">氏名</label>
            <input v-model="name" type="text" class="form-control" required />
          </div>
          <div class="mb-3">
            <label class="form-label">生年月日</label>
            <input v-model="birthday" type="date" pattern="yyyy-mm-dd" class="form-control" required />
          </div>
          <div class="mb-3">
            <label class="form-label">登録日</label>
            <input v-model="joinday" type="date" pattern="yyyy-mm-dd" class="form-control" required />
          </div>
          <div class="mb-3">
            <label for="teamSelect">チーム（部署）</label>
            <select id="teamSelect" v-model="team_id" class="form-control">
              <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.display_name }}</option>
            </select>
          </div>
          <div class="mb-3">
            <label class="form-label">自己紹介</label>
            <input v-model="self_introduction" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label class="form-label">キャリアサマリ</label>
            <input v-model="career_summary" type="text" class="form-control" />
          </div>
          <div class="mb-3">
            <label for="userTypeSelect">ユーザータイプ</label>
            <select id="userTypeSelect" v-model="user_type" class="form-control">
              <option v-for="ut in user_types" :key="ut.id" :value="ut.id">{{ ut.name }}</option>
            </select>
          </div>
          <div class="buttons mb-3">
            <button v-on:click="update" class="btn btn-primary row-button">更新</button>
            <button v-on:click="delete_user" class="btn btn-primary row-button">削除</button>
          </div>
          <div class="buttons">
            <button v-on:click="back" class="btn btn-primary row-button">戻る</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import {useRoute} from 'vue-router';
import store from "@/store";

const NORMAL_USER_TYPE = '1'
const SUPER_USER_TYPE = '2'
const NON_USER_TYPE = '3'

export default {
  data: function () {
    return {
      user_id: "",
      user: {},
      email: "",
      name: "",
      user_identifier: "",
      birthday: "",
      joinday: "",
      team_id: "",
      self_introduction: "",
      career_summary: "",
      fileInfo: '',
      fileSetted: false,
      password_change_ui: false,
      old_password: "",
      password: "",
      password_confirm: "",
      teams: [],
      user_type: NORMAL_USER_TYPE,
      user_types: [{ id: NORMAL_USER_TYPE, name: "一般ユーザー" }
                    , { id: SUPER_USER_TYPE, name: "管理者" }
                    , { id: NON_USER_TYPE, name: "お知らせ投稿用" } ],
    }
  },
  async mounted() {
    const route = useRoute()
    this.user_id = route.params.user_id;
    this.get();
    this.fetchTeams();
  },
  methods: {
    dummy: function () {
    },
    get: function () {
      axios.get("/user", {params: {user_id: this.user_id}}).then((response) => {
        this.user = response.data.user
        this.name = this.user.name
        this.email = this.user.email
        this.user_identifier = this.user.user_identifier
        this.birthday = this.user.birthday
        this.joinday = this.user.joinday
        this.self_introduction = this.user.self_introduction
        this.career_summary = this.user.career_summary
        this.team_id = this.user.tenant_team_id
        this.is_superuser = this.user.is_superuser
        this.is_nonuser = this.user.is_nonuser

        if (this.user.is_nonuser) {
          this.user_type = NON_USER_TYPE
        } else if (this.user.is_superuser) {
          this.user_type = SUPER_USER_TYPE
        }
      });
    },
    fetchTeams: function () {
      axios.get("/teams").then((response) => {
        this.teams = response.data
      });
    },
    update: function () {
      let nodes = document.querySelectorAll(`#form :invalid`)
      if (nodes.length > 0) {
        return
      }

      var is_superuser = false;
      var is_nonuser = false;
      if (this.user_type == NON_USER_TYPE) {
        is_nonuser =true;
      } else if (this.user_type == SUPER_USER_TYPE) {
        is_superuser = true;
      }

      const data = {
        user_id: this.user_id,
        name: this.name,
        user_identifier: this.user_identifier,
        birthday: this.birthday,
        joinday: this.joinday,
        tenant_team_id: this.team_id == undefined ? null : String(this.team_id),
        self_introduction: this.self_introduction == null ? '' : this.self_introduction,
        career_summary: this.career_summary == null ? '' : this.career_summary,
        is_superuser: is_superuser,
        is_nonuser: is_nonuser,
      };

      axios.post("/user_info_create_update", data)
        .then((res) => {
          // 成功時の処理
          router.push("/users");
        })
        .catch((error) => {
          alert(error.response.data.detail);
        });
    },
    delete_user: function() {
      axios.get("/user_delete", {params: {user_id: this.user_id}}).then((res) => {
        router.push("/users")
      })
    },
    fileSelected: function (event) {
      this.fileInfo = event.target.files[0];
      this.fileSetted = true
    },
    fileUpload: function () {
      const formData = new FormData()
      formData.append('file', this.fileInfo)
      axios.post('/user_image', formData, {params: {user_id: this.user_id}}).then(response => {
        console.log(response)
        router.go(0)
      });
    },
    back: function () {
      router.push("/users")
    },
    ui_open: function () {
      this.password_change_ui = true;
    },
    update_password: function () {
      if (this.password === "") {
        alert("パスワードを入力してください");
        return;
      }
      if (this.password !== this.password_confirm) {
        alert("パスワードが一致しません");
        return;
      }
      const data = {
        user_id: this.user_id,
        old_password: '',
        password: this.password,
        password_confirm: this.password_confirm,
      };
      axios.post("/user_password_update", data).then((res) => {
        router.go(0)
      })
    },
    logout: function () {
      store.dispatch("logout")
    }
  }
}
</script>
<style scoped>
.home {
  width: 900px;
  margin: 0 auto;
}

.user {
  text-align: left;
}

label {
  display: block;
  text-align: left;
}

.row-button {
  margin-right: 1em;
}

.buttons, .pass {
  text-align: left;
}

p {
  padding: 0;
}

img {
  width: 300px;
  height: 400px;
  margin: 0 auto;
  display: block;
}
</style>
