<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>チーム（部署）編集</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="user">
          <form id="form" v-on:submit.prevent="dummy">
          <div class="mb-3">
            <label class="form-label">チーム名</label>
            <input v-model="name" type="text" class="form-control" required placeholder="チーム名"/>
          </div>
          <div class="mb-3">
            <label class="form-label">表記名</label>
            <input v-model="display_name" type="text" class="form-control" required placeholder="表記名"/>
          </div>
          <div class="buttons mb-3">
            <button v-on:click="update" class="btn btn-primary row-b">更新</button>
            <button v-on:click="delete_team" class="btn btn-primary">削除</button>
          </div>
          <div class="buttons mb-3">
            <button v-on:click="back" class="btn btn-primary">戻る</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import {useRoute} from 'vue-router';
import store from "@/store";

export default {
  data: function () {
    return {
      id: "",
      name: "",
      display_name: "",
      team: {},
    }
  },
  async mounted() {
    const route = useRoute()
    this.id = route.params.id;
    this.get();
  },
  methods: {
    dummy: function() {},
    get: function () {
      axios.get("/team", {params: {id: this.id}}).then((response) => {
        this.team = response.data[0]
        if (this.team.tenant_id != null) {
          this.name = this.team.name
          this.display_name = this.team.display_name
        }
      });
    },
    update: function () {
      let nodes = document.querySelectorAll(`#form :invalid`)
      if(nodes.length > 0) {
        return
      }
      const data = {
        id: this.id,
        name: this.name,
        display_name: this.display_name,
      };
      axios.post("/team_update", data).then((res) => {
        router.push("/teams")
      })
    },
    delete_team: function () {
      const data = {
        id: this.id,
      };
      axios.get("/team_delete", {params: data}).then((res) => {
        router.push("/teams")
      })
    },
    back: function () {
      router.push("/teams")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
label {
  display: block;
  text-align: left;
}
.row-b {
  margin-right: 1em;
}
.buttons {
  text-align: left;
}
</style>
