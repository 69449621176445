<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <h1>ありがとう一覧</h1>
  <div v-for="thank in thanks" :key="thank.id" class="p-3 mb-1 container text-start" style="background-color: #eee">
    <p class="mb-0">{{thank.issue_date}}</p>
    <p class="mt-0 mb-0">「{{thank.from_name}}」から「{{thank.to_name}}」へ</p>
    <p class="mt-0 mb-0">{{thank.comment}}</p>
    <p class="mt-0 mb-0">コメント数: <span v-if="thank.comment_count > 0"><router-link :to="'/comments/' + thank.id" target="_blank">{{thank.comment_count}}</router-link></span><span v-else>{{thank.comment_count}}</span></p>
    <button v-on:click="deleteThanks(thank.id)" class="btn btn-primary">削除</button>
  </div>

  <div v-if="next_exists" class="mt-3 mb-3">
    <button class="btn btn-lg btn-primary" v-on:click="more">さらにみる</button>
  </div>
  <div v-else class="mb-3">

  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "ThanksView",
  data: function() {
    return {
      thanks: [],
      page: 1,
      next_exists: true,
    }
  },
  mounted: function() {
    this.get();
  },
  methods: {
   get: async function() {
     let data = {
       page: this.page,
       max_id: "",
     };
     await axios.get("/thx_post_from_to", {params: data}).then((response) => {
       if(response.data && response.data.length == 0) {
         this.next_exists = false;
       }
       response.data.forEach(function(x){
         if(x.is_active === true) {
           this.thanks.push(x)
         }
       },this)
     });
   },
  deleteThanks: async function (thank_id) {
    console.log("削除:" + thank_id);
    let data = {
      thx_post_id: thank_id.toString(),
    };
    try {
      const response = await axios.post("/delete_thx_post", data);
      if (response.data.status == "OK") {
        let i = 0;
        for (let x = 0; x < this.thanks.length; x++) {
          if (this.thanks[x].id == thank_id) {
            i = x;
            break;
          }
        }
        this.thanks.splice(i, 1);
      }
    } catch (error) {
      console.error("Error deleting thank post:", error);
    }
  },
   more: function() {
     this.page++;
     this.get();
   }
  }
}
</script>

<style scoped>

</style>
