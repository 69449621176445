import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import router from '../router';
import axios from 'axios';

const store = createStore({
  plugins: [createPersistedState()],
  state: {
    refresh_token: '',
    access_token: '',
    loggedInUser: {},
    isAuthenticated: false,
  },
  mutations: {
    setRefreshToken: function (state, refreshToken) {
      state.refresh_token = refreshToken;
      state.isAuthenticated = true;
    },
    setAccessToken: function (state, accessToken) {
      state.access_token = accessToken;
      state.isAuthenticated = true;
    },
    // sets state with user information and toggles
    // isAuthenticated from false to true
    setLoggedInUser: function (state, user) {
      state.loggedInUser = user;
      state.isAuthenticated = true;
    },
    // delete all auth and user information from the state
    clearUserData: function (state) {
      state.refresh_token = '';
      state.access_token = '';
      state.loggedInUser = {};
      state.isAuthenticated = false;
    },
  },
  actions: {
    logIn: async ({commit}, payload) => {
      const loginUrl = '/token_superuser';
      try {
        await axios.post(loginUrl, payload).then((response) => {
          if (response.status === 200) {
            commit('setRefreshToken', response.data.refresh_token);
            commit('setAccessToken', response.data.access_token);
            router.push({name: 'menu'});
          } else {
            alert("ログインに失敗しました。入力内容をご確認ください。");
          }
        });
      } catch (e) {
        alert("ログインに失敗しました。入力内容をご確認ください。");
        console.log(e);
      }
    },
    refreshToken: async ({state, commit}) => {
      const refreshUrl = '/token/refresh';
      try {
        await axios
            .get(refreshUrl, {
              headers: {
                Authorization: `Bearer ${state.refresh_token}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                commit('setAccessToken', response.data.access_token);
                commit('setRefreshToken', response.data.refresh_token);
              }
            });
      } catch (e) {
        console.log(e.response);
      }
    },
    logout: async ({commit}) => {
      const url = '/token/revoke';
      try {
        await axios.get(url).then((response) => {
          commit('clearUserData')
          router.push({name: 'welcome'});
        });
      } catch (e) {
        console.log(e);
      }
    },
    get_user_id: ({state}) => {
      if ("" === state.access_token) {
        return 0;
      }
      const base64Url = state.access_token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jwt = JSON.parse(decodeURIComponent(escape(window.atob(base64))));
      return jwt.user_id
    },
    /**
     * 画像をリサイズ
     * @param commit
     * @param payload
     * @returns {Promise<string|null>}
     */
    // eslint-disable-next-line no-empty-pattern,no-unused-vars
    resizeImage: async ({commit}, payload) => {
      let imageData = payload.imageData;
      let width = payload.width;
      let height = payload.height;
      try {
        const context = document.createElement('canvas').getContext('2d')
        if (context == null) {
          return null
        }
        // 画像のサイズを取得
        const image = await new Promise((resolve, reject) => {
          const image = new Image()
          image.addEventListener('load', () => resolve(image))
          image.addEventListener('error', reject)
          image.src = URL.createObjectURL(imageData)
        })
        const {naturalHeight: beforeHeight, naturalWidth: beforeWidth} = image
        // 変換後の高さと幅を算出
        const afterWidth = width
        const afterHeight = height
        // Canvas 上に描画
        context.canvas.width = afterWidth
        context.canvas.height = afterHeight
        // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
        context.drawImage(image, 0, 0, beforeWidth, beforeHeight, 0, 0, afterWidth, afterHeight)
        // JPEGデータにして返す
        return context.canvas.toDataURL(`image/png`, 0.9)
      } catch (err) {
        console.error(err)
        return null
      }
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    accessToken: (state) => state.access_token,
    refreshToken: (state) => state.refresh_token,
  },
});

export default store;
