<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>ユーザー一括作成</h1>
    <br>
    <div>
      <a @click.prevent="downloadCSVTemplate" href="#">Download CSV Template</a>
    </div>
    <br><br>
    <div>
      <input type="file" @change="handleFileChange" />
      <button :disabled="isSubmitting" @click="uploadCSV">Upload CSV</button>
    </div>
    <br>
    <div v-if="message" :style="{ color: isSuccess ? 'blue' : 'red'}">
        {{ message }}
    </div>
    <br><br>
    <div>
      <h5>所属は以下のIDを指定してください</h5>
      <table class="team-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>名称</th>
            <th>表記名</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="team in teams" :key="team.id">
            <td>{{ team.id }}</td>
            <td>{{ team.name }}</td>
            <td>{{ team.display_name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      selectedFile: null,
      isSubmitting: false,
      isSuccess: false,
      message: "", // メッセージを格納するデータプロパティを追加
      teams: []
    };
  },
  mounted() {
    this.fetchTeams();
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async fetchTeams() {
      try {
        axios.get('/teams').then(response => {
          this.teams = response.data; // メッセージをセット
        });
      } catch (error) {
        console.error(error);
      }
    },
    async downloadCSVTemplate() {
        axios.get('/users_csv_template', {responseType: 'blob',}).then(response => {
          try {
            // Blobからファイルを作成
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8' });
            // ダウンロード用のリンクを作成し、クリックしてダウンロード
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'users_template.csv';
            link.click();
          } catch (error) {
            console.error(error);
          }
        });
    },
    async uploadCSV() {
      if (this.isSubmitting) {
        // 既にサブミット中の場合は何もしない
        return;
      }

      if (this.selectedFile == null) {
        this.message = "ファイルを指定してください。";
        return;
      }
      this.isSubmitting = true;
      this.message = "";
      this.isSuccess = false;

      const formData = new FormData();
      formData.append('file', this.selectedFile);

      try {
        axios.post('/users_csv_upload', formData).then(response => {
          console.log(response.data.result);
          if (response.data.result) {
            this.isSuccess = true;
          }
          this.message = response.data.message; // メッセージをセット
        }).catch((error) => {
          alert(error.response.data.detail);
        });
      } catch (error) {
        console.log(error);
        this.message = "エラーが発生しました。"; // エラーメッセージをセット
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
<style scoped>
.team-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 600px; /* 任意の最大幅を指定 */
  margin: 0 auto; /* 中央寄せ */
}

.team-table th, .team-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.team-table th {
  background-color: #f2f2f2;
}
</style>