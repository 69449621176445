<template>
  <div class="login-container">
    <div class="login-box">
      <h1 class="login-title">THXily</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <input type="text" v-model="tenantcd" placeholder="会社ID" required class="form-input" />
        </div>
        <div class="form-group">
          <input type="text" v-model="userid" placeholder="Email" required class="form-input" />
        </div>
        <div class="form-group">
          <input type="password" v-model="password" placeholder="Password" required class="form-input" />
        </div>
        <button type="submit" class="login-button" :disabled="loading">
          <span v-if="!loading">ログイン</span>
          <span v-else class="spinner"></span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import store from '../store';
import qs from 'qs';
export default {
  data() {
    return {
      tenantcd: '',
      userid: '',
      password: '',
      loading: false
    };
  },
  methods: {
    async login() {
      this.loading = true;
      try {
        await store.dispatch(
          'logIn',
          qs.stringify({
            client_id: this.tenantcd,
            username: this.userid,
            password: this.password,
          })
        );
      } catch (error) {
        // エラーハンドリング
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background: #f1f1f1;
  color: #333;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  padding-top: 10vh;
  background-color: #eaeaea;
  /* 薄いグレーの背景色 */
}

.login-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-title {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
  color: #0073aa;
}

.form-group {
  margin-bottom: 20px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;
}

.form-input:focus {
  border-color: #0073aa;
  outline: none;
}

.login-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #0073aa;
  color: #fff;
  font-size: 1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  position: relative;
}

.login-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0073aa;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.login-button:hover:not(:disabled) {
  background-color: #005177;
  transform: translateY(-2px);
}

.login-button:active:not(:disabled) {
  transform: translateY(0);
}
</style>
