<template>
  <div>
    THXily managiment console
  </div>
</template>

<script>
import store from "../store";
import router from "../router";

export default {
  name: 'WelcomeView',
  mounted() {
    if (!store.getters.isAuthenticated) {
      router.push("/login")
    } else {
      router.push("/menu")
    }
  },
  components: {}
}
</script>
