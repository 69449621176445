<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログイン</a>
  </nav>
  <div class="user container">
    <h1>ポイント付与</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="home">
          <div class="user">
            <form v-on:submit.prevent="create">
            <div class="mb-3">
              <label class="form-label">付与ポイント数</label>
              <input v-model="point_num" type="text" class="form-control" pattern="\d+" required placeholder="10"/>
            </div>

            <div class="buttons mb-3">
              <button type="submit" class="btn btn-primary">付与</button>
            </div>
            <div class="buttons mb-3">
              <button v-on:click="back" class="btn btn-primary">戻る</button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      point_num: "",
    }
  },
  async mounted() {
  },
  methods: {
    create: function () {
      const data = {
        point_num: this.point_num,
      }
      axios.post("/update_point", data).then((res) => {
        router.push("/menu")
      });
    },
    back: function () {
      router.push("/menu")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
.home {
  /*  width: 900px;
    margin: 0 auto;*/
}

label {
  display: block;
  text-align: left;
}

.buttons {
  text-align: left;
}

img {
  width: 300px;
  height: 400px;
}
</style>
