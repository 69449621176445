import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView';
import LoginView from '../views/LoginView';
import MenuView from "@/views/MenuView";
import UsersView from "@/views/UsersView";
import UsersCreateView from "@/views/UsersCreateView";
import UserView from "@/views/UserView";
import UserCreateView from "@/views/UserCreateView";
import TeamCreateView from "@/views/TeamCreateView";
import TeamsView from "@/views/TeamsView";
import TeamView from "@/views/TeamView";
import InfoCreateView from "@/views/InfoCreateView";
import InfosView from "@/views/InfosView";
import InfoView from "@/views/InfoView";
import AddPointView from "@/views/AddPointView";
import PointInfoCsvView from "@/views/PointInfoCsvView";
import CommentsView from "@/views/CommentsView";
import ThanksView from "@/views/ThanksView";
import ThxCardsView from "@/views/ThxCardsView";
import ThxCardCreateView from "@/views/ThxCardCreateView";
import ThxCardUpdateView from "@/views/ThxCardUpdateView";
import HeaderCreateView from "@/views/HeaderCreateView";
import ThxInfoPostCreateView from '@/views/ThxInfoPostCreateView.vue';
import ThxInfosView from '@/views/ThxInfosView.vue';

const routes = [
  {
    path: '/point_info_csv',
    name: 'point_info_csv',
    component: PointInfoCsvView,
  },
  {
    path: '/add_point',
    name: 'add_point',
    component: AddPointView,
  },
  {
    path: '/team_create',
    name: 'team_create',
    component: TeamCreateView,
  },
  {
    path: '/teams',
    name: 'teams',
    component: TeamsView,
  },
  {
    path: '/team/:id',
    name: 'team',
    component: TeamView,
  },
  {
    path: '/info_create',
    name: 'info_create',
    component: InfoCreateView,
  },
  {
    path: '/infos',
    name: 'infos',
    component: InfosView,
  },
  {
    path: '/thx_info_post_create',
    name: 'thx_info_post_create',
    component: ThxInfoPostCreateView,
  },
    {
    path: '/thx_infos',
    name: 'thx_infos',
    component: ThxInfosView,
  },
  {
    path: '/info/:info_id',
    name: 'info',
    component: InfoView,
  },
  {
    path: '/user_create',
    name: 'user_create',
    component: UserCreateView,
  },
  {
    path: '/users',
    name: 'users',
    component: UsersView,
  },
  {
    path: '/users_create',
    name: 'users_create',
    component: UsersCreateView,
  },
  {
    path: '/user/:user_id',
    name: 'user',
    component: UserView,
  },
  {
    path: '/',
    name: 'welcome',
    component: WelcomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuView,
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: ThanksView
  },
  {
    path: '/comments/:id',
    name: 'comments',
    component: CommentsView
  },
  {
    path: '/thx_cards',
    name: 'thx_cards',
    component: ThxCardsView
  },
  {
    path: '/thx_card_create',
    name: 'thx_card_create',
    component: ThxCardCreateView
  },
  {
    path: '/thx_card_update/:id',
    name: 'thx_card_update',
    component: ThxCardUpdateView
  },
  {
    path: '/header_create',
    name: 'header_create',
    component: HeaderCreateView
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
