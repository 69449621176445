<!-- ColorPicker.vue -->
<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1 style="text-align: center">ヘッダ編集</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <form id="form" v-on:submit.prevent="dummy">
          <div class="mb-3">
            <div class="image-container" v-if="fileInfo !== ''">
              <img :src="fileInfo" class="d-block" :style="{ width: imageSize.width, height: imageSize.height }"/>
            </div>
            <div>
              <input type="file" class="mt-4" v-on:change="fileSelected"/>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">メインカラー</label>
            <input type="color" v-model="selectedColor" @input="updateColor" />
          </div>
          <div class="mb-3">
            <label class="form-label">サブカラー</label>
            <input type="color" v-model="selectedSubColor" @input="updateSubColor" />
          </div>
          <div class="buttons mb-3">
            <button v-on:click="update" class="btn btn-primary row-button">更新</button>
            <!-- <button v-on:click="delete_user" class="btn btn-primary row-button">削除</button> -->
          </div>
          <div class="buttons">
            <button v-on:click="back" class="btn btn-primary row-button">戻る</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.user {
  text-align: left;
}
label {
  display: block;
  text-align: left;
}
p {
  padding: 0;
}
</style>
<script>
import axios from "axios";
import router from "@/router";
export default {
  props: {
    value: {
      type: String, // 親コンポーネントからのバリデーションを追加することができます
      default: '#ffffff', // 初期色
    },
    imageSize: {
      type: Object,
      default: () => ({ width: '300px', height: 'auto' }), // Set default size here
    },
  },
  async mounted() {
    this.get();
  },
  data() {
    return {
      selectedColor: this.value,
      selectedSubColor: this.value,
      fileSetted: false,
      fileInfo: '',
      fileInfo2: '',
      base64Image: '',
    };
  },
  methods: {
    dummy: function () {
    },
    get: function () {
      axios.get("/tenant_branding").then((response) => {
        if (response.data.logo) {
          this.fileInfo = "data:image/png;base64," + response.data.logo;
        }
        if (response.data.main_color) {
          this.selectedColor = response.data.main_color;
          this.selectedSubColor = response.data.sub_color;
        }
      });
    },
    convertToBase64(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        // base64Stringを使って何かを行うか、stateにセットするなどの処理を行う
        this.base64Image = base64String;
      };
      reader.readAsDataURL(file);
    },
    updateColor() {
      console.log(this.selectedColor);
      this.$emit('input', this.selectedColor);
    },
    updateSubColor() {
      console.log(this.selectedSubColor);
      this.$emit('input', this.selectedSubColor);
    },
    fileSelected: function (event) {
      // Assuming you want to display the selected image
      const file = event.target.files[0];
      this.fileInfo = URL.createObjectURL(file);
      this.fileSetted = true;
      this.convertToBase64(file);
    },
    update: function () {
      const data = {
        file: this.base64Image,
        main_color: this.selectedColor,
        sub_color: this.selectedSubColor,
      };
      const formData = new FormData()
      // formData.append('file', this.fileInfo)
      formData.append('main_color', this.selectedColor)
      formData.append('sub_color', this.selectedSubColor)

      axios.post('/create_tenant_branding', data).then(response => {
        alert("更新しました。");
        router.push("/menu");
      });
    },
    back: function () {
      router.push("/menu")
    },
  },
};
</script>
