<template>
  <nav>
    <a href="javascript:void(0)" v-on:click="close()">閉じる</a>
  </nav>
  <h1>コメント一覧</h1>
  <div v-for="comment in comments" :key="comment.id" class="p-3 mb-1 container text-start" style="background-color: #eee">
    <p class="mb-0"><span v-if="comment.reply">返信: </span>「{{comment.name}}」からコメント</p>
    <p class="mt-0 mb-0">{{comment.comment}}</p>
    <button v-on:click="deleteComment(comment.id)" class="btn btn-primary">削除</button>
  </div>

  <div v-if="next_exists" class="mt-3 mb-3">
    <button class="btn btn-lg btn-primary" v-on:click="more">さらにみる</button>
  </div>
  <div v-else class="mb-3"></div>

</template>

<script>
import axios from "axios";
import {useRoute} from 'vue-router';

export default {
  name: "CommentView",
  data: function() {
    return {
      point_id: 0,
      comments: [],
      page: 1,
      next_exists: true,
    }
  },
  mounted: function() {
    const route = useRoute()
    this.point_id = route.params.id
    this.get();
  },
  methods: {
   get: async function() {
     let data = {
       thx_post_id: this.point_id,
       page: this.page,
       max_id: "",
     };
     await axios.get("/from_comment", {params: data}).then((response) => {
       let this_obj = this
       if(response.data && response.data.length == 0) {
         this.next_exists = false;
       }
       response.data.forEach(function(x){
         if(x.is_active === true) {
           x.reply = false;
           this.comments.push(x)
         }
         x.replay_comment.forEach(function(y){
           if(y.is_active === true) {
             y.reply = true
             this.comments.push(y)
           }
         },this_obj)
       },this_obj)
     });
   },
   deleteComment: async function(comment_id) {
     console.log("削除:" + comment_id);
     let data = {
       thx_comment_id: comment_id,
     };
     await axios.get("/delete_comment", {params: data}).then((response) => {
       if(response.data.status == "OK") {
         let i = 0;
         for(let x = 0; x < this.comments.length; x++) {
           if(this.comments[x].id == comment_id) {
             i = x;
             break
           }
         }
         this.comments.splice(i,1)
       }
     });
   },
   more: function() {
     this.page++;
     this.get();
   },
   close: function() {
     window.close();
   }
  }
}
</script>

<style scoped>

</style>
