<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>チーム追加</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="home">
          <div class="user">
            <form v-on:submit.prevent="create">
            <div class="mb-3">
              <label class="form-label">チーム名（正式名）</label>
              <input v-model="name" type="text" class="form-control" required placeholder="チーム名"/>
            </div>
            <div class="mb-3">
              <label class="form-label">チーム名（表記名）</label>
              <input v-model="display_name" type="text" class="form-control" required placeholder="表記名"/>
            </div>

            <div class="buttons mb-3">
              <button type="submit" class="btn btn-primary">作成</button>
            </div>
            <div class="buttons mb-3">
              <button v-on:click="back" class="btn btn-primary">戻る</button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      name: "",
      display_name: "",
    }
  },
  async mounted() {
  },
  methods: {
    create: function () {
      const data = {
        id: "",
        name: this.name,
        display_name: this.display_name,
      }
      axios.post("/create_team", data).then((res) => {
        router.push("/teams")
      });
    },
    back: function () {
      router.push("/menu")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
.home {
  /*  width: 900px;
    margin: 0 auto;*/
}

label {
  display: block;
  text-align: left;
}

.buttons {
  text-align: left;
}

img {
  width: 300px;
  height: 400px;
}
</style>
