<template>
  <div class="container">
    <nav class="navbar">
      <h1 class="menu-title">メニュー</h1>
      <a class="logout" v-on:click="logout">ログアウト</a>
    </nav>
    <div class="home">

      <div class="section user-section">
        <h2 class="section-title">ユーザー管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in userMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <button v-on:click="menu.action" class="btn btn-primary">{{ menu.text }}</button>
          </div>
        </div>
      </div>

      <div class="section info-section">
        <h2 class="section-title">お知らせ管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in infoMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <button v-on:click="menu.action" class="btn btn-primary">{{ menu.text }}</button>
          </div>
        </div>
      </div>

      <div class="section info-post-section">
        <h2 class="section-title">お知らせ投稿管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in infoPostMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <button v-on:click="menu.action" class="btn btn-primary">{{ menu.text }}</button>
          </div>
        </div>
      </div>

      <div class="section card-section">
        <h2 class="section-title">カード管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in cardMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <router-link :to="menu.route" class="btn btn-primary">{{ menu.text }}</router-link>
          </div>
        </div>
      </div>

      <div class="section team-section">
        <h2 class="section-title">チーム管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in teamMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <button v-on:click="menu.action" class="btn btn-primary">{{ menu.text }}</button>
          </div>
        </div>
      </div>

      <div class="section thx-section">
        <h2 class="section-title">ありがとう一覧</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in thxMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <router-link :to="menu.route" class="btn btn-primary">{{ menu.text }}</router-link>
          </div>
        </div>
      </div>

      <div class="section sys-section">
        <h2 class="section-title">システム管理</h2>
        <div class="card-grid">
          <div class="card" v-for="menu in sysMenus" :key="menu.text">
            <i :class="menu.icon"></i>
            <router-link :to="menu.route" class="btn btn-primary">{{ menu.text }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../router";
import store from "@/store";
export default {
  data() {
    return {
      teamMenus: [
        { text: 'チーム（部署）一覧', action: this.getTeams, icon: '' },
        { text: 'チーム（部署）作成', action: this.getCreateTeam, icon: '' }
      ],
      userMenus: [
        { text: 'ユーザー一覧', action: this.getUsers, icon: '' },
        { text: 'ユーザー作成', action: this.getCreateUser, icon: '' },
        { text: 'ユーザー一括作成(csv)', action: this.getCreateUsers, icon: '' }
      ],
      infoMenus: [
        { text: 'お知らせ一覧', action: this.getInfos, icon: '' },
        { text: 'お知らせ作成', action: this.getCreateInfo, icon: '' }
      ],
      infoPostMenus: [
        { text: 'お知らせ投稿一覧', action: this.getThxInfos, icon: '' },
        { text: 'お知らせ投稿作成', action: this.getCreateThxInfoPost, icon: '' }
      ],
      cardMenus: [
        { text: 'カード一覧', route: 'thx_cards', icon: '' },
        { text: 'カード作成', route: 'thx_card_create', icon: '' }
      ],
      thxMenus: [
        { text: 'ありがとう一覧', route: 'thanks', icon: '' }
      ],
      sysMenus: [
        { text: 'ヘッダ変更', route: 'header_create', icon: '' }
      ]
    };
  },
  methods: {
    getCreateUser: function () {
      router.push('/user_create');
    },
    getCreateUsers: function () {
      router.push('/users_create');
    },
    getUsers: function () {
      router.push('/users');
    },
    getCreateTeam: function () {
      router.push("/team_create")
    },
    getTeams: function () {
      router.push("/teams")
    },
    getCreateInfo: function () {
      router.push("/info_create")
    },
    getInfos: function () {
      router.push("/infos")
    },
    getCreateThxInfoPost: function () {
      router.push("/thx_info_post_create")
    },
    getThxInfos: function () {
      router.push("/thx_infos")
    },
    getAddPoint: function () {
      router.push("/add_point")
    },
    getPointInfoCsv: function () {
      router.push("/point_info_csv")
    },
    getCreateHeader: function () {
      router.push("/header_create")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
};
</script>

<style scoped>

.container {
  font-family: 'Arial', sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  background-color: #333;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
}

.menu-title {
  font-size: 2em;
  color: #fff;
}

.logout {
  font-size: 1em;
  color: #ffffff;
  background-color: #ff0000;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.logout:hover {
  background-color: #e60000;
}

.home {
  text-align: center;
}

.section {
  margin-bottom: 40px;
  padding: 20px;
  border-radius: 10px;
}

.team-section {
  background-color: #e3f2fd;
}

.user-section {
  background-color: #ffebee;
}

.info-section {
  background-color: #fff3e0;
}

.info-post-section {
  background-color: #ffe0b2;
}

.card-section {
  background-color: #e8f5e9;
}

.thx-section {
  background-color: #f3e5f5;
}

.sys-section {
  background-color: #e0f7fa;
}

.section-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card i {
  font-size: 2em;
  margin-bottom: 10px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.btn {
  font-size: 1em;
  padding: 10px 20px;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}
</style>
