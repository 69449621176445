<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="container">
    <h1>お知らせ一覧</h1>
    <div class="d-flex justify-content-center">
      <ul class="col-lg-5">
        <li class="info-li" v-for="info in infos" :key="info.id" v-on:click="to_info(info.id)">
          <div class="info mb-4">
            <p>
              お知らせ日時: {{ info.issue_date }}<br/>
              {{ info.title }}<br/>
              <a v-on:click="to_info(info.id)" class="btn btn-primary">編集</a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      infos: []
    }
  },
  async mounted() {
    await axios.get("/infos").then((response) => {
      this.infos = response.data
    });
  },
  methods: {
    to_info: function (info_id) {
      router.push("/info/" + info_id)
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}
.info {
  cursor: pointer;
}
/*
p {
  padding: 1em;
  text-align: left;
  margin-top: 1em;
}
*/
</style>
