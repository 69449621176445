<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="container">
    <h1>チーム（部署）一覧</h1>
    <div class="d-flex justify-content-center">
      <ul class="col-lg-5">
        <li class="team-li" v-for="team in teams" :key="team.id" v-on:click="to_team(team.id)">
          <div class="team mb-4">
            <p>
              {{ team.name }}<br/>
              <a v-on:click="to_team(team.id)" class="btn btn-primary">編集</a>
            </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import store from "@/store";

export default {
  data: function () {
    return {
      teams: []
    }
  },
  async mounted() {
    await axios.get("/teams").then((response) => {
      this.teams = response.data
    });
  },
  methods: {
    to_team: function (team_id) {
      router.push("/team/" + team_id)
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0;
}
.team {
  cursor: pointer;
}
/*
p {
  padding: 1em;
  text-align: left;
  margin-top: 1em;
}
*/
</style>
