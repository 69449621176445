<template>
  <nav>
    <router-link to="/menu">メニュー</router-link>
    |
    <a class="logout" v-on:click="logout">ログアウト</a>
  </nav>
  <div class="user container">
    <h1>お知らせ編集</h1>
    <div class="user row justify-content-center">
      <div class="col-lg-5">
        <div class="user">
          <form id="form" v-on:submit.prevent="dummy">
          <div class="mb-3">
            <label class="form-label">タイトル</label>
            <input v-model="info_title" type="text" class="form-control" required placeholder="お知らせタイトル"/>
          </div>
          <div class="mb-3">
            <label class="form-label">本文</label>
            <textarea v-model="info_body" class="form-control" required placeholder="お知らせ本文"/>
          </div>
          <div class="mb-3">
            <label class="form-label">お知らせ日時</label>
            <input name="hoge" v-model="issue_date" type="date" pattern="\d\d\d\d\-\d\d-\d\d" class="form-control" required placeholder="お知らせ日時"/>
          </div>
          <div class="buttons mb-3">
            <button v-on:click="update" class="btn btn-primary row-b">更新</button>
            <button v-on:click="delete_info" class="btn btn-primary">削除</button>
          </div>
          <div class="buttons mb-3">
            <button v-on:click="back" class="btn btn-primary">戻る</button>
          </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import router from "@/router";
import {useRoute} from 'vue-router';
import store from "@/store";

export default {
  data: function () {
    return {
      info_id: "",
      issue_date: "",
      info_title: "",
      info_body: "",
      info: {},
    }
  },
  async mounted() {
    const route = useRoute()
    this.info_id = route.params.info_id;
    this.get();
  },
  methods: {
    dummy: function() {},
    get: function () {
      axios.get("/info", {params: {info_id: this.info_id}}).then((response) => {
        this.info = response.data[0]
        if (this.info.tenant_id != null) {
          this.info_title = this.info.title
          this.issue_date = this.info.issue_date
          this.info_body = this.info.body
        }
      });
    },
    update: function () {
      let nodes = document.querySelectorAll(`#form :invalid`)
      if(nodes.length > 0) {
        return
      }
      const data = {
        info_id: this.info_id,
        info_title: this.info_title,
        info_body: this.info_body,
        issue_date: this.issue_date
      };
      axios.post("/info_update", data).then((res) => {
        router.push("/infos")
      }).catch((error) => {
        alert(error.response.data.detail);
      });
    },
    delete_info: function () {
      const data = {
        info_id: this.info_id,
      };
      axios.get("/info_delete", {params: data}).then((res) => {
        router.push("/infos")
      })
    },
    back: function () {
      router.push("/infos")
    },
    logout: function () {
      store.dispatch("logout")
    },
  }
}
</script>
<style scoped>
label {
  display: block;
  text-align: left;
}
.row-b {
  margin-right: 1em;
}
.buttons {
  text-align: left;
}
</style>
